import React, { Component } from 'react';

import { Row, Col } from '../components/grid';
import Button from '../components/form/button';
import Select from '../components/form/select';
import Textfield from '../components/form/textfield';

import { selectStyles } from '../style-utils.js';



const ROLES = [
	{ value: 'admin', label: 'Administratör' },
	{ value: 'principal', label: 'Rektor' },
	{ value: 'teacher', label: 'Lärare' },
	{ value: 'other', label: 'Annat' },
]

const INTEREST = [
	{ value: 'elevhalsa', label: 'Admentum Elevhälsa' },
	{ value: 'grundskola', label: 'Admentum Grundskola' },
	{ value: 'gymnasieskola', label: 'Admentum Gymnasieskola' },
]

class ContactForm extends Component {
	render () {
		return (
			<form name="demo-form" method="post" action="/contact-success" netlify-honeypot="bot-field" data-netlify="true" autoComplete="off">
				<Row>
					<Col width={{sm: 1, md:1/2}}>
						<Textfield
							label="Namn"
							name="name"
						/>

						<Textfield
							label="Skola / Organisation"
							name="school"
						/>

						<Select
							styles={selectStyles}
							options={ROLES}
							defaultValue={ROLES[0]}
						/>
					</Col>

					<Col width={{sm: 1, md:1/2}}>
						<Textfield
							label="E-post"
							name="email"
						/>

						<Textfield
							label="Telefon"
							name="Phone"
						/>

						<Select
							styles={selectStyles}
							options={INTEREST}
							defaultValue={INTEREST[0]}
						/>
					</Col>
				</Row>

				<input type="hidden" name="form-name" value="demo-form" />
				{/* <input type="submit" value="Skicka" className="special" /> */}
				<Button
					color="#fff"
					hoverColor="#000"
					type="submit"
				>
					Skicka
				</Button>
			</form>
		)
	}
}

export default ContactForm
