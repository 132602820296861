import React, { Component } from 'react';
import { graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Features from '../components/features';
import { Row, Col } from '../components/grid'
import Layout from '../components/layout'
import PageSection from '../components/page-section'
import SEO from '../components/seo'
import SectionTitle from '../components/section-title'
import VideoSection from '../components/video-section';
import ContactForm from '../components/contact-form-elevhalsa';
import Image from '../components/image';
import styled from 'styled-components';
import Button from '../components/form/button';

import theme from '../theme';

const ImageBox = styled.div`
	height: auto;
	margin-bottom: 20px;

	@media screen and (min-width: 768px) {
		max-height: 400px;
	}
	@media screen and (min-width: 950px) {
		max-height: 600px;
	}
`;


const FEATURES = [{
	title: 'Särskilt stöd',
	info: 'Dokumentera allt kring arbetet med särskilt stöd. Anmälan – utredning/kartläggning – åtgärdsprogram – utvärdering. Rektor får stöd i sitt beslutsfattande i alla steg och alla beslut signeras med e-legitimation direkt i systemet. Få full kontroll över alla ärenden med hjälp av en överskådlig timeline.'
}, {
	title: 'Plan mot diskriminering och kränkande behandling',
	info: 'Dokumentera planen direkt i systemet. Gör dokumentet levande på riktigt med hjälp av funktionerna för det främjande och förebyggande arbetet. Direkta utvärderingar underlättar skapande av framtida planer.'
}, {
	title: 'Arbete med kränkande behandling',
	info: 'Dokumentera allt kring arbetet mot trakasserier och kränkande behandling. Anmälan – utredning – handlingsplan – utvärdering. Rektor får stöd i sitt beslutsfattande i alla steg och alla beslut signeras med e-legitimation direkt i systemet. Få full kontroll över alla ärenden med hjälp av en överskådlig timeline.'
}, {
	title: 'Egna anteckningar och digitala protokoll',
	info: 'Alla kompetenser inom elevhälsan har ett utrymme att skriva egna anteckningar. Antingen kopplat direkt till en elev eller som anteckningar för det egna minnet. Anteckningarna kan även användas för att föra elevhälsoteamets protokoll på ett säkert och tillgängligt sätt.'
}, {
	title: 'Extra anpassningar',
	info: 'Har man Admentum Elevhälsa i kombination med Admentum Skola visas de extra anpassningarna i IUP:en. Om man använder Elevhälsan som en fristående modul så kan man fylla i och följa upp de extra anpassningarna direkt i systemet.'
}, {
	title: 'Arkiv',
	info: 'Här samlas alla avslutade ärenden så länge skolan har behov av informationen. Om skolan är i behov av att skicka informationen vidare till exempelvis Skolinspektionen är det lätt att med ett knapptryck ta ut all dokumentation i ett ärende.'
}, {
	title: 'Kunskapsprognoser',
	info: 'Ingen elev ska falla mellan stolarna! Täta kunskapsuppföljningar är ett sätt att undvika det. Prognosfunktionen möjliggör tidiga insatser och rätt stödåtgärder i ett led till målet ”alla elever har rätt att lyckas”.'
}, {
	title: 'Främjande och förebyggande arbete',
	info: 'Dokumentera och följ upp det främjande arbetet på grupp- och skolnivå. Med hjälp av systemets unika uppföljning minimeras risken att insatserna inte utvärderas och mäts.'
}]


class SchoolHealthPage extends Component {
	render () {
		return (
            <Layout>
				<SEO
					title={'Admentum Elevhälsa'}
					description={`Admentums fristående produkt Elevhälsa revolutionerar arbetet inom elevhälsan. Med modulen samarbetar elevhälsopersonal, lärare och vårdnadshavare på ett enkelt och säkert sätt kring arbetet med särskilt stöd och mot kränkande behandling.`}
				/>

				<VideoSection
					title={'Admentum Elevhälsa'}
					subtitle={'Alla elever rätt att lyckas!'}
					content={(
						<div>
							<p>
								Admentums fristående produkt Elevhälsa revolutionerar arbetet inom elevhälsan.
								Med Admentum Elevhälsa samarbetar elevhälsopersonal, lärare och vårdnadshavare på ett
								enkelt och säkert sätt kring arbetet med särskilt stöd och mot kränkande behandling.
							</p>

							<AnchorLink href='#demo'>
								<Button
									color="#fff"
									hoverColor="#000"
								>
									Jag vill ha en personlig visning
								</Button>
							</AnchorLink>
						</div>
					)}
					background={'#bcd9b5'}
					videoAlign={'bottom'}
					textColor={theme.colors.white}
					breakPoint={850}
					video="https://s3.eu-central-1.amazonaws.com/skolplattformen-assets/ADM_set3_v4_3mbps.mp4"
					videoStyles={{ maxHeight: '430px' }}
					videoWrapperStyles={{ maxHeight: '530px' }}
					videoWidthPercent={60}
				/>

				<PageSection>
					<SectionTitle
						title="Elevhälsoarbetet"
						center
					/>

					<Row>
						<Col width={{sm: 1, md:1/2}}>
							<p>
								All elevhälsodokumentation samlat på ett ställe i ett säkert digitalt system med
								smarta lösningar. Nu är det slut på inlåsta pärmar! Admentums Elevhälsomodul
								revolutionerar elevhälsoteamets arbetssätt genom att lyfta in det i en helt
								digital och molnbaserad miljö. Resultatet blir ett effektivare och säkrare EHT.<br />
								Det är enkelt att komma igång med arbetet då Admentums Elevhälsa har ett intuitiv
								och enkelt gränssnitt.
							</p>
							<p>
								Arbeta säkert med digitala dokument. Gör dokumenten tillgängliga för de som behöver
								ha tillgång till dem. Allt i en GDPR-säkrad miljö. Säkerställ att ni arbetar utifrån
								Skollagen, systemets smarta lösningar hjälper rektor att följa skollag och Skolverkets
								allmänna råd. Enkelt, säkert, smart helt enkelt.
							</p>
						</Col>
						<Col width={{sm: 1, md:1/2}}>
							<p>
								I vårt unika system för dokumentation inom elevhälsa har både lärare och
								vårdnadshavare tillgång till nödvändig information. Detta för att få slut
								på mailande av digitala och fysiska dokument som skickas till hemmet för
								påskrift. I Admentum sker alla påskrifter och beslut med hjälp av e-signering.
								Utifrån GDPR får du full kontroll på tillgången till de olika dokumenten.
							</p>
							<p>
								För att efterleva Skollagens 3 kap. 25 §:s intention ”Elevhälsan ska främst vara
								förebyggande och hälsofrämjande. Elevernas utveckling mot utbildningens mål ska
								stödjas.” går det även att arbeta systematiskt med främjande och förebyggande
								insatser direkt i systemet.
							</p>
							<p>
								I Admentum Elevhälsa hanteras anmälan, pedagogisk kartläggning/utredning,
								åtgärdsprogram, utvärdering, kunskapsprognoser, främjande och förebyggande
								projekt, plan mot kränkande behandling, extra anpassningar, anteckningar för
								respektive elevhälsokompetens, kommunikation och arbete mot kränkande behandling
								från anmälan till handlingsplan.
							</p>
						</Col>
					</Row>
				</PageSection>

				<PageSection>
					<SectionTitle
						title="Funktioner"
						center
					/>
					<Features features={FEATURES} />
				</PageSection>

				<PageSection>
				<SectionTitle
					title="Vad säger våra kunder?"
					center
				/>
					<Row>
					<Col width={{sm: 1, md:1/3}}>
						<a href="https://futuraskolan.se/sv/"><ImageBox style={{backgroundColor: theme.colors.blue}}>
							<Image image={this.props.data.futuraskolanlogo.childImageSharp.gatsbyImageData} style={{height: '100%'}} />
						</ImageBox></a>

						<p>"Admentum skola is a fully functioning documentation and communication base from which to run a solid,
						effective organization.

						That’s an impressive accomplishment! And it wouldn’t have happened without your expertise,
						dedication and professionalism.

						We’re grateful. Top work, you helped us do our job on a level we can be proud of."</p>
<p><b>Tom Callahan,</b> CEO - Futuraskolan</p>
					</Col>

					<Col width={{sm: 1, md:1/3}}>
						<a href="http://raoulwallenbergskolan.se/"><ImageBox style={{backgroundColor: theme.colors.blue}}>
							<Image image={this.props.data.rwslogo.childImageSharp.gatsbyImageData} style={{height: '100%'}} />
						</ImageBox></a>

						<p>"Vi ska bli bäst i Sverige på IT i skolan. Admentum i kombination med Canvas LMS är
						viktiga delar i arbetet mot den visionen"</p>
<p><b>Per Egon Johansson,</b> VD och grundare - Raul Wallenbergskolorna</p>
					</Col>

					<Col width={{sm: 1, md:1/3}}>
						<a href="https://gnestawaldorfskola.se/"><ImageBox style={{backgroundColor: theme.colors.blue}}>
							<Image image={this.props.data.gwslogo.childImageSharp.gatsbyImageData} style={{height: '100%'}} />
						</ImageBox></a>

						<p>"Precis som vi möter och utvecklar eleverna varifrån de befinner sig möter nu
						Admentum oss där vi är och utvecklar sitt utbud så att det passar vår pedagogiska idé."</p>
<p><b>Christine Hagen,</b> Rektor - Gnesta Waldorf</p>
					</Col>

					</Row>
				</PageSection>
{/* 
				<PageSection
					backgroundColor={'#bbd9b5'}
					color={'#fff'}
				>
					<div id="demo">
						<SectionTitle
							title={'Boka en demo'}
						/>
						<p>
						Är du nyfiken på Admentum och hur vi kan förenkla din vardag som skolledare? Boka en demo med oss idag! Fyll i dina kontaktuppgifter nedan så hör vi av så så snart vi har möjlighet.
						</p>

						<ContactForm />
					</div>
				</PageSection> */}

			</Layout>
        );
	}
}

export default SchoolHealthPage

export const pageQuery = graphql`query SchoolHealthQuery {
  parallaxImage: file(relativePath: {eq: "dummy.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  screenImage: file(relativePath: {eq: "screen-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  screenImage2: file(relativePath: {eq: "screen-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  futuraskolanlogo: file(relativePath: {eq: "Futuraskolan_2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  rwslogo: file(relativePath: {eq: "RWS_2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  gwslogo: file(relativePath: {eq: "GWS_2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  appImage: file(relativePath: {eq: "app.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 820, placeholder: NONE, layout: CONSTRAINED)
    }
  }
}
`
