import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import FeatureRow from '../components/feature-row';
import { Col, Row } from '../components/grid';

import { latoBold } from '../style-utils.js';
import theme from '../theme';


const FeaturesWrapper = styled.span`
	.col {
		margin: 20px auto;

		&:first-child {
			text-align: right;
		}

		h3 {
			${latoBold}
			font-size: 0.625rem;
			color: #333;
			margin-bottom: 8px;
		}
	}

	@media screen and (max-width: ${props => theme.breakpoints.md}) {
		.col {
			margin: 10px auto;

			&:first-child {
				text-align: left;
			}
		}
	}
`;

const Features = props => {
	let rowFeatures = [];
	let currentRow = [];
	props.features.forEach((feature, index) => {
		if (index !== 0 && index % 2 === 0) {
			rowFeatures.push(currentRow);
			currentRow = [];
		}
		currentRow.push(feature);
	});

	if (currentRow.length) {
		rowFeatures.push(currentRow);
	}

	return (
		<FeaturesWrapper>
			{rowFeatures.map((rowFeature, index) => (
				<Row key={'row-' + index} className="row">
					{rowFeature.map((feature, featureIndex) => (
						<Col
							width={{ sm: 1, md: 1 / 2 }}
							key={featureIndex}
							className="col"
						>
							<FeatureRow title={feature.title} info={feature.info} />
						</Col>
					))}
				</Row>
			))}
		</FeaturesWrapper>
	);
};

Features.propTypes = {
	features: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			info: PropTypes.string
		})
	)
}

export default Features;
