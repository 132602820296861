import React from 'react';

const FeatureRow = (props) => {
	return (
		<div>
			<h3>{props.title}</h3>
			<p>
				{props.info}
			</p>
		</div>
	);
}

export default FeatureRow;
